import React, { useState } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";

const Map = ({ data }) => {
  const {
    markers,
    center,
    zoom,
    legend,
    className,
    iconSize,
    iconAnchor,
    filtered,
    mapUrl,
    mapAttribution,
  } = data;
  const [selectedFilter, setSelectedFilter] = useState("all");

  if (!data) {
    return null;
  }

  const handleFilterChange = (filter) => {
    setSelectedFilter(filter);
  };

  const filteredMarkers =
    selectedFilter === "all"
      ? markers
      : markers.filter((marker) => marker.icon === selectedFilter);

  return (
    <div className="map-container">
      {filtered && (
        <div className="map-filters-container">
          <div className="map-filter">
            <input
              type="radio"
              id="all"
              name="filter"
              value="all"
              checked={selectedFilter === "all"}
              onChange={() => handleFilterChange("all")}
              className={`${
                selectedFilter === "all" ? "active" : ""
              } filter-input`}
            />
            <label htmlFor="all" className="map-filter-label">
              <p className="map-filter-title">Display all steps</p>
            </label>
          </div>
          {legend &&
            Object.entries(legend).map(
              ([key, { legendIcon, title, description }]) => (
                <div key={key} className="map-filter">
                  <input
                    type="radio"
                    id={key}
                    name="filter"
                    value={key}
                    checked={selectedFilter === key}
                    onChange={() => handleFilterChange(key)}
                    className={`${
                      selectedFilter === key ? "active" : ""
                    } filter-input`}
                  />
                  <label htmlFor={key} className="map-filter-label">
                    <img
                      src={legendIcon}
                      alt={`${title} icon`}
                      className="map-filter-icon"
                    />
                    <span className="map-filter-info">
                      <p className="map-filter-title">{title}</p>
                      <p className="map-filter-description">{description}</p>
                    </span>
                  </label>
                </div>
              )
            )}
        </div>
      )}
      <MapContainer
        center={center}
        zoom={zoom}
        scrollWheelZoom={false}
        style={{ height: "100vw" }}
        className={className}
      >
        <TileLayer
          attribution={
            mapAttribution ||
            '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          }
          url={mapUrl || "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"}
          maxZoom={25}
        />
        {Array.isArray(filteredMarkers) &&
          filteredMarkers.map((marker) => {
            const { position, icon, popup } = marker;

            return (
              <Marker
                position={position}
                key={position.toString()}
                icon={
                  new L.icon({
                    iconUrl: legend?.[icon]?.["icon"],
                    iconSize: iconSize || [30, 30],
                    iconAnchor: iconAnchor || null,
                    className: "spa-marker-icon",
                  })
                }
              >
                {popup && (
                  <Popup>
                    <div className={"spa-map-popup-container"}>
                      <div className={"spa-map-popup-image-wrapper"}>
                        {popup?.popupImage && (
                          <img src={popup?.popupImage} alt="Popup Image" />
                        )}
                      </div>
                      <p>{popup?.popupText}</p>
                    </div>
                  </Popup>
                )}
              </Marker>
            );
          })}
      </MapContainer>
    </div>
  );
};

export default Map;
